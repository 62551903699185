<template>
  <div id="College">
    <div class="col_part1">
      <div class="part1_content flex_col">
        <div class="top flex">
          <div class="top_content flex_col">
            <div class="title flex_col">
              <span class="title queue-bottom">慧点学院</span>
            </div>
            <div class="title_content queue-bottom">
              精选企业运营干货，涵括各类惠企补贴攻略及各行业研究报告，更有财税、法律、营销等主题线上线下惠企活动。
            </div>
          </div>
        </div>
        <div class="bottom">
          <baskMask />
        </div>
      </div>
    </div>
    <div class="col_part2">
      <div class="part2_content flex">
        <div>
          <span class="base_font queue-bottom" ref="part2_1"
            >热门政策 融资资讯</span
          >
          <p class="queue-bottom" ref="part2_2">100+深圳地区热门惠企补贴攻略</p>
        </div>
        <img src="@/assets/images/college/part1.png" alt="" />
      </div>
    </div>
    <div class="col_part3">
      <div class="part3_content flex">
        <img src="@/assets/images/college/part2.png" alt="" />
        <div>
          <span class="base_font queue-bottom" ref="part3_1">专家解读</span>
          <span class="base_font queue-bottom" ref="part3_2"
            >官方权威惠企政策解读视频</span
          >
        </div>
      </div>
    </div>
    <div class="col_part4">
      <div class="part4_content flex">
        <div class="content">
          <span class="queue-bottom" ref="part4_1">惠企系列活动</span>
          <p class="queue-bottom" ref="part4_2">
            线上线下主题活动，内容涵括政策、财税、法律、营销等
          </p>
        </div>
        <img src="@/assets/images/college/part3.png" alt="" />
      </div>
    </div>
    <div class="col_part5">
      <div class="part5_content flex">
        <img src="@/assets/images/college/part4.png" alt="" />
        <div>
          <span class="base_font queue-bottom" ref="part5_1">干货课件下载</span>
          <span class="base_font queue-bottom" ref="part5_2"
            >各行业研究报告，讲师课件，一键下载</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import baskMask from '@/common/baseMask.vue'

export default {
  components: {
    baskMask
  },
  data () {
    return {
    }
  },
  computed: {},
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      if (e.target.documentElement.scrollTop >= 650) {
        if (this.$refs.part2_1 && this.$refs.part2_2) {
          this.$refs.part2_1.style.display = 'block'
          this.$refs.part2_2.style.display = 'block'
        }
      }
      if (e.target.documentElement.scrollTop >= 1650) {
        if (this.$refs.part3_1 && this.$refs.part3_2) {
          this.$refs.part3_1.style.display = 'block'
          this.$refs.part3_2.style.display = 'block'
        }
      }
      if (e.target.documentElement.scrollTop >= 2350) {
        if (this.$refs.part4_1 && this.$refs.part4_2) {
          this.$refs.part4_1.style.display = 'block'
          this.$refs.part4_2.style.display = 'block'
        }
      }
      if (e.target.documentElement.scrollTop >= 2950) {
        if (this.$refs.part5_1 && this.$refs.part5_2) {
          this.$refs.part5_1.style.display = 'block'
          this.$refs.part5_2.style.display = 'block'
        }
      }
    }
  }
}

</script>{
<style lang='scss' scoped>
#College {
  width: 100%;
  min-width: 1200px;
  .col_part1 {
    padding-top: 220px;
    width: 100%;
    background: url(~assets/images/college/bg1.png) no-repeat 50% 50%;
    background-size: cover;
    .part1_content {
      width: 1200px;
      margin: auto;
      .top {
        margin-left: 43px;
        .top_content {
          align-items: flex-start;
          flex: 1;
          .title {
            align-items: flex-start;
            span {
              font-size: 67px;
              font-weight: bold;
              line-height: 81px;
              color: #dbbb95;
              letter-spacing: 1px;
            }
          }
          .title_content {
            text-align: left;
            margin-top: 35px;
            font-size: 26px;
            font-weight: 400;
            line-height: 39px;
            color: #ffffff;
            max-width: 515px;
          }
        }
      }
      .bottom {
        background: #161616;
        transform: translateY(169px);
      }
    }
  }
  .col_part2 {
    width: 100%;
    background: url(~assets/images/college/bg2.jpg) no-repeat 50% 50%;
    background-size: cover;
    padding-top: 252px;
    .part2_content {
      width: 1200px;
      margin: auto;
      justify-content: flex-end;
      & > div {
        padding-top: 127px;
        margin-right: -87px;
        span {
          max-width: 268px;
          display: none;
        }
        p {
          white-space: nowrap;
          font-size: 26px;
          font-weight: 400;
          line-height: 43px;
          color: #ffffff;
          display: none;
        }
        p:last-of-type {
          margin-top: 30px;
        }
      }
    }
  }
  .col_part3 {
    width: 100%;
    background: url(~assets/images/college/bg3.png) no-repeat 50% 50%;
    background-size: cover;
    padding-top: 77px;
    .part3_content {
      width: 1200px;
      margin: auto;
      & > div {
        padding-top: 191px;
        padding-right: 79px;
        span {
          max-width: 402px;
          display: none;
          text-align: right;
        }
        span:nth-of-type(2) {
          font-size: 26px;
          font-weight: 400;
          line-height: 43px;
          color: #ffffff;
          margin-top: 23px;
        }
      }
    }
  }
  .col_part4 {
    width: 100%;
    background: #fff;
    .part4_content {
      padding-top: 114px;
      padding-bottom: 97px;
      width: 1200px;
      margin: auto;
      justify-content: flex-end;
      .content {
        padding-top: 144px;
        width: 448px;
        span {
          display: none;
          font-size: 67px;
          font-weight: 400;
          line-height: 87px;
          color: #333333;
          max-width: 402px;
        }
        p {
          margin-top: 20px;
          font-size: 26px;
          font-weight: 400;
          line-height: 43px;
          color: #333333;
          max-width: 448px;
          display: none;
        }
      }
    }
  }
  .col_part5 {
    width: 100%;
    background: url(~assets/images/college/bg4.jpg) no-repeat 50% 50%;
    background-size: cover;
    padding-top: 51px;
    .part5_content {
      width: 1200px;
      margin: auto;
      & > div {
        padding-top: 177px;
        margin-left: 44px;
        span {
          display: none;
          text-align: right;
        }
        span:nth-of-type(2) {
          font-size: 26px;
          font-weight: 400;
          line-height: 43px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
